<template>
    <div class="container1150">
        <header><strong>智慧水务管理平台</strong></header>
        <main>
            <p>公司承担了多个全国城市的智慧水务系统建设</p>
            <!-- <div class="img-list">
                <div class="img1"></div>
                <div class="img2"></div>
            </div> -->
            <p>● · 滕州市城区排水管网智慧平台</p>
            <div class="img1"></div>
            <p>● · 合流制截排系统只能调度管控平台（坪山）</p>
            <div class="img2"></div>
            <p>● · 顺德区流域治理一体化数字平台</p>
            <div class="img3"></div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .text-img {
            display: flex;
            justify-content: space-around;
            p {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 500px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img1 {
            // width: 100%;
            width: 1100px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/14-1.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 1100px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/14-2.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1100px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/14-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 900px;
            height: 1100px;
            margin: 10px auto;
            background-image: url("/img/product/7-6.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 900px;
            height: 1100px;
            margin: 10px auto;
            background-image: url("/img/product/7-7.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>